<template>
  <div>
    <loading :loading="isLoading"></loading>
    <b-row class="content-wrapper pill-result" align-v="center" align-h="center">
      <b-col cols="12" sm="6" lg="4" xl="3">
        <transition name="fade-short" mode="out-in">
          <Card center v-if="!isLoading && isComplete" >
            <template v-slot:content>
              <div class="text-center">
                <b-iconstack font-scale="5" class="mb-5" >
                  <b-icon stacked icon="shield-fill" variant="success" scale="1"></b-icon>
                  <b-icon stacked icon="check" variant="white"></b-icon>
                </b-iconstack>
                <h4 class="text-center mb-2">{{ $t('PILLS.EXECUTION.result_title') }}</h4>

                <p class="paragraph mb-4">
                  {{ $t('PILLS.EXECUTION.result_description') }}
                </p>

                <b-button variant="primary" @click="$router.go(-3)" block>{{ $t('PILLS.EXECUTION.back_to_pill_list') }}</b-button>
              </div>
            </template>
          </Card>

          <Card center v-else-if="!isLoading && !isComplete" >
            <template v-slot:content>
              <div class="text-center">
                <b-iconstack font-scale="5" class="mb-5" >
                  <b-icon stacked icon="shield-fill" variant="danger" scale="1"></b-icon>
                  <b-icon stacked icon="exclamation" variant="white"></b-icon>
                </b-iconstack>
                <h4 class="text-center mb-4">{{ $t('ERRORS.general') }}</h4>

                <b-button variant="primary" @click="$router.push({ name: 'pills' })" block>{{ $t('PILLS.EXECUTION.back_to_pill_list') }}</b-button>
              </div>
            </template>
          </Card>
        </transition>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { PILL_STATUS } from '@/config/constants'
import ErrorsMixin from '@/services/mixins/errors.mixins'

export default {
  name: 'PillExecutionResult',
  mixins: [ErrorsMixin],
  data () {
    return {
      pill: { }
    }
  },
  methods: {
    ...mapActions({
      getPillDetail: 'pills/getSmallPillDetail'
    })
  },
  computed: {
    ...mapGetters({
      requestedPill: 'pills/getPillDetail',
      isLoading: 'pills/isLoading'
    }),

    isComplete () {
      if (typeof this.pill.student_pill_completion === 'undefined') return false
      if (typeof this.pill.student_pill_completion.completion_status === 'undefined') return false

      return this.pill.student_pill_completion.completion_status === PILL_STATUS.COMPLETE
    }
  },
  mounted () {
    return this.getPillDetail(this.$route.params.id)
      .then(_ => {
        this.pill = this.requestedPill
      })
      .catch(err => {
        this.handleAxiosError(err)
      })
  }

}
</script>

<style lang="scss" scoped>
.pill-result {
  background-color: var(--primary-dark);
}

.content-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
</style>
